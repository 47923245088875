.home-area {
  background-color: #111;
  background-image: url("../../../public/home-background.jpg");
  background-position: center center;
  height: calc(var(--vh,1vh) * 100);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.element-cover-bg {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.element-cover-bg::before {
  background-color: rgba(0, 0, 0, 0.45);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.home-area .text-center {
  /* will move text up */
  /* margin-bottom: 3rem; */
}

.home-area .home-name {
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 34px;
}

.last-name-container {
  position: relative;
  display: inline-block;
}

.non-colored {
  color: transparent;
	-webkit-text-stroke: 2px var(--accentColor);
}

.colored {
  color: var(--accentColor);
  position: absolute;
  left: 0;
	animation: flowtext 4s ease-in-out infinite;
}

.home-area .home-headline {
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: .3px;
  margin-bottom: 0;
}

.home-area .home-headline .single-headline {
  display: inline-block;
  position: relative;
  text-align: left;
  vertical-align: top;
}

.home-area .home-headline .single-headline b.is-visible {
  opacity: 1;
  position: relative;
}

.home-area .home-headline .single-headline b {
  display: inline-block;
  font-weight: 400;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home-area .home-headline .single-headline::after {
  background-color: #b9b9b9;
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
}

.home-area .fixed-wrapper {
  margin: 0 50px;
  position: relative;
}

.home-area .fixed-wrapper .fixed-block.block-left {
  left: 0;
}

.home-area .fixed-wrapper .fixed-block {
  bottom: 34px;
  position: absolute;
}

.home-area .fixed-wrapper .languages-list {
  margin-bottom: 0;
}

.home-area .fixed-wrapper .languages-list a {
  background-color: transparent;
  text-decoration: none;
}

.home-area .fixed-wrapper .languages-list .single-language {
  -webkit-transform: rotate(90deg);
  color: #e6e6e6;
  display: block;
  margin-top: 20px;
  text-transform: uppercase;
  transform: rotate(90deg);
}

.home-area .fixed-wrapper .fixed-block.block-right {
  right: 0;
}

.home-area .fixed-wrapper .social-icons {
  margin-bottom: 0;
}

.home-area .fixed-wrapper .social-icons > li {
  line-height: 100%;
  margin-top: 14px;
  text-align: center;
}

.home-area .fixed-wrapper .social-icons .home-icon {
  color: #f3f3f3;
  font-size: 23px;
  line-height: 100%;
}

@media (max-width: 991.98px)
{
  .home-area .home-name {
    font-size: 62px;
  }

  .home-area .home-headline {
    font-size: 30px;
  }
}

@media (max-width: 767.98px) {
  .home-area .fixed-wrapper {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .home-area .home-name {
    font-size: 48px;
  }

  .home-area .home-headline {
    font-size: 26px;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@keyframes flowtext {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}

@keyframes gearSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
