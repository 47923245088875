#project-wrapper .main-article {
	position: relative;
	z-index: 1;
	background: #1F1F1F;
	padding: 50px 60px;
	-webkit-box-shadow: 0 3px 12px -1px #1B1C1E,0 22px 27px -30px #1B1C1E;
	box-shadow: 0 3px 12px -1px #1B1C1E,0 22px 27px -30px #1B1C1E;
}

#project-wrapper .featured-image {
	margin: -50px -60px 40px;
	margin-bottom: 40px;
	position: relative;
	z-index: 2
}

.decoration-border {
	position: relative
}

.decoration-border:before {
	content: '';
	border: 2px solid #fff;
	position: absolute;
	left: 4px;
	top: 4px;
	right: 4px;
	bottom: 4px;
	z-index: 2
}

.image-loader {
	position: relative
}

.image-loader:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	z-index: -1;
	background-image: -webkit-gradient(linear,left top,right top,color-stop(0,rgba(211,211,211,0)),color-stop(50%,rgba(211,211,211,.15)),to(rgba(211,211,211,0)));
	background-image: -o-linear-gradient(left,rgba(211,211,211,0) 0,rgba(211,211,211,.15) 50%,rgba(211,211,211,0) 100%);
	background-image: linear-gradient(90deg,rgba(211,211,211,0) 0,rgba(211,211,211,.15) 50%,rgba(211,211,211,0) 100%);
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 50% 100%;
}

.fullwidth {
	display: block;
	width: 100%;
	height: auto
}

.image-loader:after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	z-index: -2;
	background: #f9f9fe
}

.clear {
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0
}

#project-wrapper .main-title {
	margin-bottom: 20px;
	word-break: break-word
}

#project-wrapper #comments.hosted .comment .comment-author,.title,.wpcf7 label {
	font-weight: 700;
	font-size: 30px;
	margin-bottom: 40px;
	line-height: 1.45;
	letter-spacing: 0px;
	color: #F0F0F0;
}

.textcenter {
	text-align: center!important
}

#project-wrapper .left-content .meta {
	text-align: center
}

div.meta {
	color: #F0F0F0;
	font-size: 15px
}

div.meta .meta-info {
	margin-right: 12px;
	white-space: nowrap
}

time:not(.icon) {
	position: relative;
	color: #F0F0F0
}

div.meta .min-read {
	display: inline-block;
	cursor: default;
	text-transform: lowercase
}

div.meta .min-read:before {
	content: '';
	display: inline-block;
	width: 15px;
	height: 2px;
	background: var(--accentColor);
	vertical-align: middle;
	margin-right: 15px
}

#project-wrapper .post-content {
	margin-top: 45px
}

div.text {
	font-size: 17px;
	line-height: 2;
	font-weight: 400;
	letter-spacing: .6px
}

div.text>:first-child {
	padding-top: 0
}

div.text figure,div.text iframe,div.text p {
	padding-top: 8px;
	margin-bottom: 24px
}

div.text b,div.text strong {
	color: var(--accentColor);
	font-weight: 700;
	-webkit-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}

div.text a:not([class]) {
	color: dodgerblue;
	text-decoration: underline;
	font-weight: 600
}

.wp-block-image {
	margin: 0 0 1em
}

div.text img {
	max-width: 100%;
	height: auto;
	-webkit-box-shadow: 0 3px 5px -2px rgba(7,10,25,.2);
	box-shadow: 0 3px 5px -2px rgba(7,10,25,.2)
}

.wp-block-image img {
	height: auto;
	max-width: 100%;
	vertical-align: bottom
}

div.text .wp-block-image figcaption {
	display: block;
	margin-bottom: 0;
	font-size: 13px;
	margin-top: .5em;
	text-align: center;
	opacity: .85
}

div.text figcaption {
	display: block;
	margin-bottom: 0;
	text-align: center;
	font-size: 13px;
	opacity: .85;
	margin-top: .5em;
	width: 100%
}

.wp-block-image figcaption {
	margin-top: .5em;
	margin-bottom: 1em
}

div.text .wp-block-quote {
	padding: 40px;
	padding-left: 60px;
	margin: 0 0 25px;
	border-left: 0;
	font-style: italic!important;
	position: relative;
	z-index: 1;
	font-size: 110%;
	background: no-repeat 97% 90% url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='40' height='40'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M19.417 6.679C20.447 7.773 21 9 21 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311-1.804-.167-3.226-1.648-3.226-3.489a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179zm-10 0C10.447 7.773 11 9 11 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C4.591 12.322 3.17 10.841 3.17 9a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179z' fill='rgba(218,222,237,1)'/%3E%3C/svg%3E");
	line-height: 1.85;
	font-weight: 400;
	background-size: 50px
}

div.text .wp-block-quote p:last-of-type {
	margin-bottom: 0
}

div.text .wp-block-quote p {
	padding-top: 0
}

div.text .wp-block-quote cite,div.text .wp-block-quote__citation {
	display: block;
	margin-top: 15px;
	color: #F0F0F0;
	font-style: normal;
	font-size: 15px;
	font-weight: 700;
	text-transform: none
}

div.text .wp-block-gallery {
	margin-left: 0;
	margin-bottom: 24px
}

.wp-block-gallery.has-nested-images {
	display: flex;
	flex-wrap: wrap
}

.blocks-gallery-grid,.wp-block-gallery {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0;
	margin: 0
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) {
	align-self: inherit
}

.wp-block-gallery.has-nested-images figure.wp-block-image:not(#individual-image) {
	margin: 0 var(--gallery-block--gutter-size,16px) var(--gallery-block--gutter-size,16px) 0;
	width: calc(50% - var(--gallery-block--gutter-size, 16px)/2)
}

.wp-block-gallery.has-nested-images figure.wp-block-image {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	position: relative;
	margin-top: auto;
	margin-bottom: auto;
	flex-direction: column;
	max-width: 100%
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) a,.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) img {
	width: 100%;
	flex: 1 0 0%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image)>a,.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image)>div:not(.components-drop-zone) {
	display: flex
}

.wp-block-gallery.has-nested-images figure.wp-block-image>a,.wp-block-gallery.has-nested-images figure.wp-block-image>div {
	margin: 0;
	flex-direction: column;
	flex-grow: 1
}

.wp-block-gallery.has-nested-images figure.wp-block-image img {
	display: block;
	height: auto;
	max-width: 100%!important;
	width: auto
}

.wp-block-gallery.has-nested-images figure.wp-block-image:not(#individual-image):last-of-type:not(#individual-image),.wp-block-gallery.has-nested-images figure.wp-block-image:not(#individual-image):nth-of-type(2n) {
	margin-right: 0
}

.wp-block-gallery.has-nested-images figcaption {
	flex-grow: 1;
	flex-basis: 100%;
	text-align: center
}

div.text h3 {
	font-size: 24px;
	padding-top: 20.8px;
	margin-bottom: 14.4px;
	line-height: 1.5
}

div.text h1,div.text h2,div.text h3,div.text h4,div.text h5,div.text h6 {
	font-weight: 700;
	color: #F0F0F0;
}

div.text ol,div.text ul {
	margin: 0 0 0 -30px;
  margin-bottom: 24px;
}

div.text ol li {
	margin-left: 20px;
}

div.text ol li::marker {
	color: var(--accentColor);
}

div.text ul,div.text ul li {
	list-style-type: disc;
	list-style-position: inside;
	position: relative
}

div.text ul:not([class]) li {
	position: relative;
	z-index: 1;
	list-style: none;
	padding-left: 20px
}

div.text ul:not([class]) li:before {
	content: '';
	position: absolute;
	left: 0;
	top: 14px;
	width: 5px;
	height: 5px;
	background: VAR(--accentColor);
	border-radius: 50%
}

div.text ol,div.text li {
	padding-bottom: 8px
}

div.text ol li:last-of-type,div.text ul li:last-of-type {
	padding-bottom: 0
}

div.text .ep-shortcode a:hover,div.text a.ep-shortcode:hover {
	text-decoration: none;
	transition: all .3s ease;
}

div.text .wp-block-image a:hover img {
	transition: all .3s ease;
	-webkit-transform: translateY(-2px);
	-ms-transform: translateY(-2px);
	transform: translateY(-2px);	
}

div.text .wp-block-image a img {
	transition: all .3s ease;
}





@media screen and (max-width: 1200px) {
  #project-wrapper #comments.hosted .comment .comment-author,.title,.wpcf7 label {
		font-size: 20px
	}
}

@media screen and (min-width: 981px) and (max-width:1200px) {
  #project-wrapper .main-article {
    padding: 40px
  }

  #project-wrapper .featured-image {
		margin: -40px -40px 40px
	}

  #project-wrapper #comments.hosted .comment .comment-author,.title,.wpcf7 label {
		font-size: 20px
	}
}

@media screen and (min-width: 768px) and (max-width:990px) {
  div.meta .meta-info {
		margin-right: 5px
	}

  div.meta .min-read:before {
		width: 8px
	}
}

@media screen and (max-width: 767px) {
  #project-wrapper .featured-image {
    margin: -25px -25px 25px
  }

  #project-wrapper #comments.hosted .comment .comment-author,.title,.wpcf7 label {
		font-size: 18px;
		margin-bottom: 20px
	}

  div.meta .meta-info {
		margin-right: 5px
	}

  div.meta .min-read:before {
		width: 8px
	}

  #project-wrapper .post-content {
		margin-top: 20px
	}

  div.text {
		font-size: 14px
	}

  div.text figure,div.text iframe,div.text p {
		margin-bottom: 12.8px
	}

  div.text h3 {
		font-size: 18px
	}

  div.text ol,div.text ul {
		margin-left: -15px
	}

	div.text .wp-block-quote {
    font-size: 13px;
    background-size: 20px;
    background-position: 10px 15px;
    padding: 15px;
    padding-left: 40px!important;
	}
}

@media (min-width: 600px) {
  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image) {
		margin-right: var(--gallery-block--gutter-size,16px);
		width: calc(33.33% - var(--gallery-block--gutter-size, 16px)*0.66667)
	}

  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):nth-of-type(3n+3) {
		margin-right: 0
	}

	.wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2),.wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2)~figure.wp-block-image:not(#individual-image) {
		width: calc(50% - var(--gallery-block--gutter-size, 16px)*0.5)
	}

	.wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:last-child {
		width: 100%
	}
}

@media screen and (max-width: 600px) {
	#project-wrapper .main-article {
		padding: 25px;
	}

	#project-wrapper .featured-image {
		margin: 0px 0px 25px
	}
}