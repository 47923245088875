.single-card {
	display: block;
}

.single-card a:not(.btn) {
	color: var(--accentColor);
}

.single-card a {
	transition: all .15s ease-in-out;
}

.portfolio-section .portfolio-grid .portfolio-wrapper {
	-ms-flex-align: center;
	align-items: center;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 30px;
	overflow: hidden;
	position: relative;
}

.portfolio-section .portfolio-grid .portfolio-wrapper:before {
	background-color: rgba(0,0,0,.9);
	cursor: pointer;
	bottom: 0;
	content: '';
	height: 100%;
	opacity: 0;
	position: absolute;
	transition: all .15s ease-in-out;
	visibility: hidden;
	width: 100%;
	z-index: 1;
}

.portfolio-section .portfolio-grid .portfolio-wrapper:hover:before {
	opacity: 1;
	visibility: visible;
}

.portfolio-section .portfolio-grid .portfolio-wrapper>.img-fluid {
	width: 100%;
}

.portfolio-section .portfolio-grid .portfolio-wrapper .item-content {
	left: 0;
	opacity: 0;
	padding: 3px 18px;
	position: absolute;
	text-align: center;
	transition: all .15s ease-in-out;
	visibility: hidden;
	width: 100%;
	z-index: 2;
}

.portfolio-section .portfolio-grid .portfolio-wrapper:hover .item-content {
	opacity: 1;
	visibility: visible;
}

.portfolio-section .portfolio-grid .portfolio-wrapper .item-content .content-title {
	color: #fff;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.8;
	margin-bottom: 10px;
}

.portfolio-section .portfolio-grid .portfolio-wrapper .item-content .content-more {
	display: inline-block;
	font-weight: 600;
	color: var(--accentColor);
}