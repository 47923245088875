.customizer-container {
  z-index: 999;
  position: fixed;
  top: 20%;
  right: -200px;
  width: 200px;
  background-color: #222;
  padding: 40px 20px 33px 30px;
  transition: all .3s ease;
}

#customizer.opened {
  right: 0;
}

.customizer-container .icon-container {
  position: absolute;
  top: 0;
  right: 100%;
  background-color: #222;
  padding: 10px 10px;
}

.customizer-container .icon-container .icon {
  color: #fff;
  width: 30px;
  height: 30px;
  -webkit-animation: gearSpin 2s linear infinite;
  animation: gearSpin 2s linear infinite;
}

.icon-container .click-field {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.icon-container .click-field:hover {
  cursor: pointer;
}

.customizer-container .wrapper {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}

.wrapper .title {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  position: relative;
  display: inline-block;
}

.wrapper .title::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: hsla(0,0%,100%,.2);
  left: 0;
  bottom: 0;
}

.wrapper .colors {
  margin: 0 0 22px;
  padding: 32px 0 0 0;
}

.wrapper .colors li {
  margin: 0;
  display: inline-block;
}

.wrapper .colors li div {
  margin-right: 5px;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.wrapper .colors li div:hover {
  cursor: pointer;
}

.wrapper .cursor {
  margin: 0;
  padding: 15px 0 0 0;
}

.wrapper .cursor li {
  margin: 0 20px 0 0;
  display: inline-block;
}

.wrapper .cursor li div {
  width: 30px;
  height: 50px;
  position: relative;
  color: #fff;
  font-weight: 600;
}

.wrapper .cursor li .cursor-icon {
  width: 30px;
  height: 30px;
  color: #fff;
  margin-bottom: 10px;
}

.wrapper .cursor li .cursor-icon:hover {
  opacity: 1;
}

.cursor .clicker {
  opacity: 0.6;
  background-color: #222;
  position: absolute;
  width: 30px;
  height: 30px;
  top: -100%;
  z-index: 1;
}

.cursor .clicker.active {
  opacity: 0;
}

.cursor .clicker:hover {
  opacity: 0;
}

@keyframes gearSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
