.pagebox-wrapper .pagebox-content .single-section:not(:last-child) {
  margin-bottom: 120px;
  /* handles below space */
}

.info-section .info-img img {
  background-color: transparent;
  border-color: rgba(0,0,0,.18);
  width: 430px;
}

.info-section .info-content .content-subtitle {
  color: var(--accentColor);
  font-size: 24px;
  margin-bottom: 15px;
}

.info-section .info-content .content-title {
  font-size: 31px;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 16px;
}

.info-content h6 {
  color: #f0f0f0;
}

.info-section .info-content p {
  color: #9f9f9f;
}

.content-description {
  line-height: 1.95;
  font-size: 15px;
}

.info-section a:not(.btn) {
  color: var(--accentColor);
}

.info-section a:focus, 
.info-section a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.info-section .info-content .content-description p:last-child {
  border-bottom: 2px solid rgba(255,255,255,.08);
  margin-bottom: 0;
  padding-bottom: 29px;
}

.info-section .info-content .content-info {
  font-size: 15px;
  margin-bottom: 4px;
  padding: 29px 0;
}

.info-section .info-content .content-info .single-info {
  margin-bottom: 10px;
}

.info-section .info-content .content-info .single-info > span {
  color: #dadada;
  font-weight: 400;
  margin-right: 10px;
}

.info-section .info-content .content-info .single-info > p {
  display: inline-block;
  margin-bottom: 0;
}

.info-section .info-content .content-info > .row:last-child .single-info {
  margin-bottom: 0;
}

.info-section .info-content .content-download {
  margin-right: 23px;
}

.btn.button-scheme {
  background-color: var(--accentColor) !important;
  border: 1px solid var(--accentColor) !important;
}

.button-main.button-scheme {
  color: #fff;
}

.button-main {
  border-radius: 30px;
  font-size: 14px;
  padding: 10px 35px;
}

.info-section .info-content .content-follow {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  line-height: 100%;
  margin-bottom: 0;
}

.info-section .info-content .content-follow::before {
  background-color: rgba(255,255,255,.25);
  content: '';
  height: 2px;
  margin-right: 23px;
  width: 60px;
}

.info-section .info-content .content-follow li:not(last-child) {
  margin-right: 14px;
}

.info-section .info-content .content-follow li {
  font-size: 21px;
}

.info-section .info-content .content-info > .row:last-child .single-info {
  margin-bottom: 0px;
}

.info-section .info-content .content-follow li a {
  color: #cecece;
}

.info-section .info-content .content-follow li a:hover {
  color: var(--accentColor);
}

.info-section .info-content .content-follow li a .about-icon {
  line-height: 100%;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .info-section .info-img img {
    -o-object-fit:cover;
    height:440px;
    object-fit:cover
   }
   .info-section .info-content .content-subtitle {
    display:none
   }
   .info-section .info-content .content-title {
    font-size:26px
   }
   .info-section .info-content .content-description {
    font-size:14px
   }
   .info-section .info-content .content-description p:last-child {
    padding-bottom:20px
   }
   .info-section .info-content .content-info {
    font-size:14px;
    padding:20px 0
   }
}

@media (max-width:991.98px) {
  .info-section .info-img img {
   -o-object-fit:cover;
   border-radius:50%;
   display:block;
   height:250px;
   margin:0 auto 40px auto;
   object-fit:cover;
   width:250px
  }
  .info-section .info-content .content-subtitle {
   font-size:18px
  }
  .info-section .info-content .content-title {
   font-size:26px
  }
  .info-section .info-content .content-description {
   font-size:14px
  }
  .info-section .info-content .content-description p:last-child {
   padding-bottom:24px
  }
  .info-section .info-content .content-info {
   font-size:14px;
   margin-bottom:0;
   padding:24px 0
  }
 }

 @media (max-width:767.98px) {
  .info-section .info-img img {
   height:200px;
   width:200px
  }
  .info-section .info-content .content-info>.row:last-child .single-info {
   margin-bottom:10px
  }
  .info-section .info-content .content-info .single-info {
   display:block;
   margin-bottom:10px;
   width:auto
  }
 }

 @media (max-width:575.98px) {
  .info-section {
   padding-bottom:51px
  }
  .info-section .info-img img {
   margin:0 auto 32px auto
  }
  .info-section .info-content .content-subtitle {
   font-size:18px
  }
  .info-section .info-content .content-title {
   font-size:22px
  }
  .info-section .info-content .content-download {
   margin-bottom:27px
  }
 }
