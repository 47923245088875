.clearfix::before, 
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

.skills-second-style .skill-value {
  font-size: 11px;
  line-height: 1.1em;
  position: relative;
  float: right;
  margin: 0 0 4px;
  color: #aaa;
}

.skills-second-style .skill-container {
  position: relative;
  display: inline-block;
  background-color: #222;
  border: 1px solid var(--accentColor);
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 10px;
  margin-bottom: 8px;
  width: 100%;
}

.skills-second-style .skill-container::before {
  content: '';
  position: absolute;
  background-color: var(--accentColor);
}

.skills-second-style .skill-percentage {
  background-color: var(--accentColor);
  border: 2px solid #222;
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
  border-radius: 9px;
  height: 8px;
  padding: 0;
  width: var(--barWidth);
}